<script setup lang="ts">
import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

const { getMediaUrl } = useGetMediaUrl()
</script>

<template>
  <div
    class="tw-absolute tw-left-0 tw-top-0 tw-z-50 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
  >
    <img
      :src="getMediaUrl('GrooverLogoLoader-Primary.gif')"
      class="tw-m-auto tw-block tw-h-full tw-max-h-12 tw-object-contain tw-object-center"
      :alt="$t('common.loading')"
    />
  </div>
</template>
